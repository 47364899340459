
import Vue from 'vue';
import axios from 'axios';

interface comission {
  src: string;
  lazySrc: string;
  title: string;
  speciece: string;
  specieceCs: string;
  specieceEn: string;
  desctiption: string;
}

export default Vue.extend({
  name: 'LatestComissions',
  data() {
    return {
      comissions: [] as comission[],
    };
  },
  methods: {
    routeTo(routeHash: string) {
      this.$router.push({ name: 'comissions', hash: '#' + routeHash });
    },
  },
  created() {
    axios
      .get('config.json')
      .then(res => {
        this.comissions = res.data.comissions;
        this.comissions.forEach(com => {
          com.speciece = this.$i18n.locale === 'en' ? com.specieceEn : com.specieceCs;
        });
      })
      .catch(err => console.log(err));
  },
});
