
import emailjs from '@emailjs/browser';
import axios from 'axios';

import Vue from 'vue';
import FormSelect from '../components/PriceQuote/FormSelect.vue';
import AnimalForm from '@/components/PriceQuote/AnimalForm.vue';
import ProtogenForm from '@/components/PriceQuote/protogenForm.vue';
import FormTable from '@/components/PriceQuote/FormTable.vue';
import BForm from '../components/Generic/BForm.vue';

export default Vue.extend({
  name: 'PriceQuoteView',
  components: { FormSelect, AnimalForm, ProtogenForm, FormTable, BForm },
  data() {
    return {
      sendMail: false,
      selectedForm: '',
      tableContent: {} as any,
      commisionsAreOpen: false as boolean,
    };
  },
  methods: {
    sendForm() {
      const emailTemplate = this.selectedForm === 'animal' ? 'template_bambi_animal' : 'template_bambi_protogen';
      emailjs.send('service_gmail_peta', emailTemplate, this.tableContent.emailVals, 'XOcIETAYuZScgoUa-');
    },
  },
  created() {
    axios
      .get('config.json')
      .then(res => {
        this.commisionsAreOpen = res.data.commisionsAreOpen;
        this.sendMail = res.data.sendMail;
      })
      .catch(err => console.log(err));
  },
});
