
import Vue from 'vue';
import AppBar from '@/components/AppLayout/AppBar.vue';
import AppFooter from './components/AppLayout/AppFooter.vue';

export default Vue.extend({
  name: 'App',
  components: { AppBar, AppFooter },
  // TO-DO uncomment for darkMode support
  beforeMount() {
    //theme
    const isDarkTheme = localStorage.getItem('isDarkTheme');
    if (isDarkTheme === null && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
      this.$vuetify.theme.dark = true;
    else if (isDarkTheme === 'true') this.$vuetify.theme.dark = true;
    else if (isDarkTheme === 'false') this.$vuetify.theme.dark = false;
  },
});
