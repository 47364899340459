
import Vue from 'vue';

export default Vue.extend({
  name: 'FormTable',
  props: {
    tableContent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableHeaders: [
        { text: 'Item', value: 'item' },
        { text: 'Price', value: 'price', align: 'right' },
      ],
    };
  },
});
