
import Vue from 'vue';

export default Vue.extend({
  name: 'UserSettings',
  methods: {
    switchLang() {
      const curLang = this.$i18n.locale;
      const newLang = curLang === 'cs' ? 'en' : 'cs';
      localStorage.setItem('lang', newLang);
      this.$i18n.locale = newLang;
      location.reload();
    },
    switchTheme() {
      const newTheme = !this.$vuetify.theme.dark;
      localStorage.setItem('isDarkTheme', String(newTheme));
      this.$vuetify.theme.dark = newTheme;
    },
  },
  computed: {
    langImg() {
      return this.$i18n.locale === 'cs' ? require('@/assets/Flag_cs.svg') : require('@/assets/Flag_en.svg');
    },
    themeIcon() {
      return this.$vuetify.theme.dark ? 'mdi-weather-night' : 'mdi-white-balance-sunny';
    },
    isMobile() {
      const size = this.$vuetify.breakpoint.name;
      return ['xs', 'sm'].includes(size);
    },
  },
});
