
import Vue from 'vue';
import axios from 'axios';

interface desctiptionList {
  content: string;
  value: string;
}

interface comission {
  src: string;
  lazySrc: string;
  title: string;
  speciece: string;
  specieceCs: string;
  specieceEn: string;
  desctiption: string;
  list: desctiptionList[];
  listEn: desctiptionList[];
  listCs: desctiptionList[];
}

export default Vue.extend({
  name: 'ComissiosList',
  data() {
    return {
      message: 'ComissiosList works!',
      comissions: [] as comission[],
    };
  },
  created() {
    axios
      .get('config.json')
      .then(res => {
        this.comissions = res.data.comissions;
        this.comissions.forEach(com => {
          com.list = this.$i18n.locale === 'en' ? com.listEn : com.listCs;
          com.speciece = this.$i18n.locale === 'en' ? com.specieceEn : com.specieceCs;
        });
      })
      .catch(err => console.log(err));
  },
});
