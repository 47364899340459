
import Vue from 'vue';
import axios from 'axios';
import { ValidationProvider } from 'vee-validate';

export default Vue.extend({
  name: 'AnimalForm',
  components: { ValidationProvider },
  data() {
    return {
      sendMail: false,
      formVals: {
        suit: {
          value: this.$t('priceQuote.animal.suitVals[0]'),
          values: this.$t('priceQuote.animal.suitVals'),
        },
        animal: {
          value: this.$t('priceQuote.animal.animalVals[0]'),
          values: this.$t('priceQuote.animal.animalVals'),
        },
        fur: 4,
        fleece: 2,
        notes: '',
        email: '',
      },
    };
  },
  watch: {
    formVals: {
      handler(formVals) {
        const suitVal = formVals.suit.value;
        const furVal = +formVals.fur;
        const fleeceVal = +formVals.fleece;

        let suitCost = 0;
        let furCost = furVal > 4 ? (furVal - 4) * 1000 : 0;
        let fleeceCost = fleeceVal > 2 ? (fleeceVal - 2) * 250 : 0;

        switch (suitVal) {
          //Mini parcial
          case formVals.suit.values[0]:
            suitCost = 17_600;
            break;
          //Parcial
          case formVals.suit.values[1]:
            suitCost = 22_000;
            furCost = furCost * 1.5;
            fleeceCost = (fleeceCost / 250) * 300;
            break;
          //Fullbody
          case formVals.suit.values[2]:
            suitCost = 50_000;
            furCost = furCost * 2;
            fleeceCost = (fleeceCost / 250) * 600;
            break;
        }

        const totalCost = suitCost + furCost + fleeceCost;
        const currency = this.$t('priceQuote.priceQuote.currencyCzk');

        const formObj = {
          tableItems: [
            { item: `${this.$t('priceQuote.animal.suitTable')} (${suitVal})`, price: `${suitCost} ${currency}` },
            { item: `${this.$t('priceQuote.animal.furTable')} (${furVal})`, price: `${furCost} ${currency}` },
            { item: `${this.$t('priceQuote.animal.fleeceTable')} (${fleeceVal})`, price: `${fleeceCost} ${currency}` },
          ],
          totalCost: totalCost,
          emailVals: {
            suit: suitVal,
            suitCost: suitCost,
            animal: formVals.animal.value,
            fur: furVal,
            furCost: furCost,
            fleece: fleeceVal,
            fleeceCost: fleeceCost,
            notes: formVals.notes,
            from: formVals.email,
            totalCost: totalCost,
          },
        };
        this.$emit('form-obj', formObj);
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    axios
      .get('config.json')
      .then(res => {
        this.sendMail = res.data.sendMail;
      })
      .catch(err => console.log(err));
  },
});
