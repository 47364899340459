
import AboutUsSocials from '@/components/AbouUs/AboutUsSocials.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'AboutUsView',
  data() {
    return {
      downloads: [
        { url: 'https://madebybambi.com/files/NFC_Tag_Manual.pdf', i18n: 'nfcTag' },
        // { url: 'https://madebybambi.com/files/Protogen_PCB.pdf', i18n: 'protogenPcb' },
      ],
    };
  },
  computed: {
    isMobile() {
      const size = this.$vuetify.breakpoint.name;
      return ['xs', 'sm'].includes(size);
    },
  },
  components: { AboutUsSocials },
});
