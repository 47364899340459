
import Vue from 'vue';

export default Vue.extend({
  name: 'TwitterFeed',
  data() {
    return {
      message: 'TwitterFeed works!',
    };
  },
  computed: {
    twitterFeedDimensions() {
      if (this.$vuetify.breakpoint.name == 'xs') return { width: '100%', ratio: 'aspect-ratio: 1 / 1' };
      else if (this.$vuetify.breakpoint.name === 'sm') return { width: '70%', ratio: 'aspect-ratio: 2 / 1' };
      else return { width: '100%', ratio: 'aspect-ratio: 2 / 1' };
    },
  },
});
