
import Vue from 'vue';

export default Vue.extend({
  name: 'AppFooter',
  data() {
    return {
      socials: [
        { icon: 'mdi-twitter', url: 'https://twitter.com/Bambilijas?t=xMcHeGzn7xZLPjwb6WElbg&s=09' },
        { icon: 'mdi-instagram', url: 'https://www.instagram.com/p/Ckh-GF0qaye/?igshid=MDJmNzVkMjY=' },
        { icon: require('@/assets/footer/telegram.svg'), url: 'https://t.me/bambisworkshop' },
        { icon: require('@/assets/footer/koFi.svg'), url: 'https://ko-fi.com/bambilijas' },
        { icon: require('@/assets/footer/discord.svg'), url: 'https://discord.gg/TJzEnSa' },
      ],
    };
  },
  methods: {
    routeTo(routeName: string) {
      this.$router.push({ name: routeName });
    },
  },
  computed: {
    isDark(): boolean {
      return this.$vuetify.theme.dark;
    },
    textTheme(): string {
      return this.isDark ? 'white--text' : 'black--text'
    },
  },
});
