
import axios from 'axios';
import Vue from 'vue';
import BForm from '@/components/Generic/BForm.vue'
import { ValidationProvider } from 'vee-validate';

export default Vue.extend({
  name: 'CommisionsAre',
  components: { BForm, ValidationProvider },
  data() {
    return {
      refreshKey: 0,
      commisionsAreOpen: false,
      dialog: false,
      sendingSubscription: false,
      subscriptionSend: false,
      subscriptionMail: localStorage.getItem('email') || ''
    };
  },
  computed: {
    emailIsSet() {
      this.refreshKey;
      return localStorage.getItem('email') != null
    },
    getEmail() {
      this.refreshKey;
      return localStorage.getItem('email')
    },
    textTheme(): string {
      return this.$vuetify.theme.dark ? 'white--text' : 'black--text'
    },
  },
  methods: {
    register() {
      this.sendingSubscription = true;
      axios.get(`https://madebybambi.com/api/addSubscriber?email=${this.subscriptionMail}`)
           .then(async res => { 
              localStorage.setItem('email', this.subscriptionMail);
              this.refreshKey++;
              this.sendingSubscription = false;
              await new Promise(resolve => setTimeout(() => { this.subscriptionSend = true; resolve('') }, 1000))
              this.dialog = false})
           .catch(err => console.log(err))
    },
    routeTo(routeName: string) {
      this.$router.push({ name: routeName });
    },
  },
  created() {
    axios
      .get('config.json')
      .then(res => this.commisionsAreOpen = res.data.commisionsAreOpen)
      .catch(err => console.log(err));
  },
});
