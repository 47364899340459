
import Vue from 'vue';

export default Vue.extend({
  name: 'FormSelect',
  data() {
    return {
      selectedForm: 'animal',
    };
  },
  watch: {
    selectedForm: {
      handler(newVal): void {
        this.$emit('selected-form', newVal);
      },
      immediate: true,
    },
  },
});
