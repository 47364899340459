
import Vue from 'vue';
import ComissionsAre from '@/components/Comissions/ComissionsAre.vue';
import ComissionsList from '@/components/Comissions/ComissionsList.vue';

export default Vue.extend({
  name: 'ComissionsView',
  components: { ComissionsAre, ComissionsList },
  data() {
    return {
      message: 'ComissionsView works!',
    };
  },
});
