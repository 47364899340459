
import CommisionsAre from '@/components/Comissions/ComissionsAre.vue';
import MainSlideShow from '@/components/Home/MainSlideShow.vue';
import LatestComissions from '@/components/Home/LatestComissions.vue';
import TwitterFeed from '@/components/Home/TwitterFeed.vue';
import Vue from 'vue';
export default Vue.extend({
  name: 'HomeView',
  components: { MainSlideShow, CommisionsAre, LatestComissions, TwitterFeed },
});
