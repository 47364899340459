
import Vue from 'vue';
import UserSettings from '../Generic/UserSettings.vue';

export default Vue.extend({
  name: 'AppBar',
  components: { UserSettings },
  data() {
    return {
      drawer: false,
      tabsModel: this.$route.name,
      tabs: [
        { text: this.$t('appBar.tabs[0]'), view: 'home' },
        { text: this.$t('appBar.tabs[1]'), view: 'merch' },
        { text: this.$t('appBar.tabs[2]'), view: 'calculator' },
        { text: this.$t('appBar.tabs[3]'), view: 'comissions' },
        { text: this.$t('appBar.tabs[4]'), view: 'about-us' },
      ],
    };
  },
  methods: {
    routeTo(routeName: string) {
      this.$router.push({ name: routeName });
    },
  },
  watch: {
    '$route.name': function (newVal) {
      this.tabsModel = newVal;
    },
  },
  computed: {
    isMobile() {
      const size = this.$vuetify.breakpoint.name;
      return ['xs', 'sm'].includes(size);
    },
    isHomePage() {
      return this.$route.name === 'home';
    },
    isDarkTheme() {
      return this.$vuetify.theme.dark;
    },
    appBarColor() {
      if (this.isHomePage) return 'rgba(0, 0, 0, 0.5)';
      else return 'primary';
    },
    tabClass() {
      if (this.isHomePage || this.isDarkTheme) {
        return {
          color: 'white',
        };
      }
      return {};
    },
    navIconColor() {
      if (this.isHomePage) return 'white';
      return '';
    },
    logoDimensions() {
      if (['xl', 'lg'].includes(this.$vuetify.breakpoint.name)) return { width: 400, height: 80 };
      return { width: 250, height: 70 };
    },
  },
  created() {
    console.log(this.$route);
  },
});
