
import Vue from 'vue';

export default Vue.extend({
  name: 'AboutUsSocials',
  data() {
    return {
      message: 'AboutUsSocials works!',
      socials: [
        {
          icon: 'mdi-twitter',
          url: 'https://twitter.com/Bambilijas?t=xMcHeGzn7xZLPjwb6WElbg&s=09',
          caption: 'Twitter',
        },
        {
          icon: 'mdi-instagram',
          url: 'https://www.instagram.com/p/Ckh-GF0qaye/?igshid=MDJmNzVkMjY=',
          caption: 'Instagram',
        },
        {
          icon: require('@/assets/footer/telegram.svg'),
          url: 'https://t.me/bambisworkshop',
          caption: 'Telegram (Workshop)',
        },
        {
          icon: require('@/assets/footer/telegram.svg'),
          url: 'https://t.me/Bambilijas',
          caption: 'Telegram (Bambilijáš)',
        },
        { icon: require('@/assets/footer/koFi.svg'), url: 'https://ko-fi.com/bambilijas', caption: 'KoFi' },
        { icon: require('@/assets/footer/discord.svg'), url: 'https://discord.gg/TJzEnSa', caption: 'Discord' },
        { icon: 'mdi-trello', url: 'https://trello.com/b/twL8gdNc/bambis-workshop', caption: 'Trello' },
        {
          icon: 'mdi-email-outline',
          url: 'mailto: info.madebybambi@gmail.com',
          caption: 'Email',
        },
      ],
    };
  },
  computed: {
    isWide() {
      return ['xl', 'lg', 'sm'].includes(this.$vuetify.breakpoint.name)
        ? 'grid-template-columns: 1fr 1fr 1fr 1fr;'
        : 'grid-template-columns: 1fr 1fr;';
    },
    isDark(): boolean {
      return this.$vuetify.theme.dark;
    },
  },
});
